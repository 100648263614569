* {
  scroll-behavior: smooth;
  margin: 0px;
}

.app {
  min-height: 100vh;
  background-color: hsl(220, 43%, 99%);
  color: rgb(8, 8, 8);
  padding-top: 100px;
  padding-bottom: 70px;
}

@media (max-width: 700px) {
  .app {
    padding-top: 90px;
  }
}

.pageTitle {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-size: 2vw;
  padding: 4px;
  border-radius: 50px;
  color: rgb(0, 0, 0);
}

@media (max-width: 1000px) {
  .pageTitle {
    font-size: 5.4vw;
  }
}
